import axios, { AxiosRequestConfig } from 'axios';
import { IconButton, Tooltip } from 'components/atoms';
import Toggle from 'components/atoms/Toggle';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { COMMON_OPTIONS, DATE_FORMAT, DATE_FORMAT_BACK_WITH_OUT_TIME, GET_EXCEPTIONS, UPDATE_EXCEPTIONS } from 'const';
import { format, startOfMonth } from 'date-fns';
import { tableModelType } from 'hooks/useTable';
import { AiOutlineEye } from 'react-icons/ai';
import { PaginationType } from 'types';
import { Exception } from 'types/entities/exception';
import { createFilterStructure, createPayload, joinObjectWithFormat } from 'utils';
import { RequiredDateSchema } from 'yup/lib/date';
import { AnyObject, TypeOfShape } from 'yup/lib/object';
import { ListExceptionTemplateFilterType } from './ListExceptionTemplate.schema';
import { updateExceptionsProps } from './ListExceptionTemplate.types';


/**
 * TODO: this function could be generalized (?)
 * Function to map the incoming rows from the server with the updated rows, and show the updated rows in the table
 * @param incomingRows rows from the server
 * @param updatedRows rows updated by the user
 * @returns 
 */
export const rowsMapper = (
  incomingRows: Exception[] | Exception | undefined,
  updatedRows: Exception[]): Exception[] => {

  if (Array.isArray(incomingRows)) {
    if (updatedRows.length === 0) {
      return incomingRows;
    }
    const updatedIncomingRows = incomingRows.map(incomingRow => {
      const updatedRow = updatedRows.find(updatedRow => updatedRow.id === incomingRow.id);
      if (!updatedRow) {
        return incomingRow;
      }
      return updatedRow;
    });
    return updatedIncomingRows;
  }

  if (incomingRows) {
    const updatedRow = updatedRows.find(updatedRow => updatedRow.id === incomingRows.id);
    if (!updatedRow) {
      return [incomingRows];
    }
    return [updatedRow];
  }

  else {
    return [];
  }
};

/**
 * Function to update a list of Exceptions in the modifiedExceptions state list variable
 * it checks if each exception already exists in the list to avoid duplicates
 * @param setModifiedExceptions 
 * @param exception 
 */
export const updateModifiedExceptions = (
  setModifiedExceptions: (value: React.SetStateAction<Exception[]>) => void,
  newExceptions: Exception[]
) => {
  setModifiedExceptions((exceptions) => {
    const updatedExceptions = [...exceptions];

    newExceptions.forEach(newException => {
      const existingIndex = exceptions.findIndex(g => g.id === newException.id);
      if (existingIndex > -1) {
        // If the exception already exists in modifiedExceptions, update its status
        updatedExceptions[existingIndex] = newException;
      } else {
        // If the exception doesn't exist in modifiedExceptions, add it
        updatedExceptions.push(newException);
      }
    });
    return updatedExceptions;
  });
}

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 15,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<Exception> = {
  field: 'updatedAt',
  order: 'DESC',
};

export const EXCEPTION_LEVELS = [
  { value: 'HIGH', name: 'Alto' },
  { value: 'MEDIUM', name: 'Medio' },
  { value: 'LOW', name: 'Bajo' },
];

export const EXCEPTION_STATUS = [
  { value: 'true', name: 'Resuelto' },
  { value: 'false', name: 'Sin resolver' },
];

export type ListExceptionFilterModel = {
  message: string | undefined,
  customer: string | undefined,
  process: string | undefined,
  level: string | undefined,
  extradata: string | undefined,
  isResolved: string | undefined,
  dateRange: TypeOfShape<{
    startDate: RequiredDateSchema<Date | undefined, AnyObject>;
    endDate: RequiredDateSchema<Date | undefined, AnyObject>;
  }>
}

export const getDefaultListExceptionTemplateFilter: () => ListExceptionTemplateFilterType = () => {
  const startDate = new Date();
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date();
  endDate.setHours(23, 59, 59, 999);

  return {
    message: '',
    customer: '',
    process: '',
    level: '',
    extradata: '',
    isResolved: '',
    dateRange: {
      startDate: startOfMonth(startDate),
      endDate,
    },
  };
}

export const messageSplitter = (message: string, wordsPerLine: number, maxLines: number) => {
  const words = message.split(' ');
  const messageList = words.reduce((result: string[], word: string, index: number) => {
    if (result.length >= maxLines + 1) return result; // stop processing words after reaching the maximum number of lines
    if (index % wordsPerLine === 0) result.push(''); // start a new string every 7 words
    result[result.length - 1] += (index % wordsPerLine ? ' ' : '') + word; // add the word to the last string in the result
    return result;
  }, []);

  if (messageList.length >= maxLines + 1) {
    messageList[maxLines] = '...'; // replace the last element with '...'
  }

  return messageList;
};

export const getColumns: (
  onViewDetails: (id: number) => void,
  onCheck: (newState: boolean, sourceElement?: number | string | number[] | string[]) => void,
) => columnType<Exception>[] = (onViewDetails, onCheck) => {

  const columns: columnType<Exception>[] = [
    {
      field: 'customer',
      headerName: 'Cliente',
      flex: '1',
      exportField: true,
      render: ({ customer }) => (customer.code),
    },
    {
      field: 'process',
      headerName: 'Proceso',
      flex: '1',
      exportField: true,
      render: ({ process }) => (process.code),
    },
    {
      field: 'updatedAt',
      headerName: 'Fecha',
      flex: '1',
      exportField: true,
      render: ({ createdAt, updatedAt }) => format(new Date((updatedAt || createdAt)), DATE_FORMAT, COMMON_OPTIONS)
    },
    {
      field: 'message',
      headerName: 'Mensaje',
      flex: '1',
      disabledSort: true,
      exportField: true,
      isRaw: true,
      overflowNone: true,
      render: ({ message }) => {
        return (
          <Tooltip
            messageList={
              messageSplitter(message, 9, 7)}
            position='right start'
            color="grey"
          >
            {message.length > 30 ? `${message.substring(0, 30)}...` : message}
          </Tooltip>
        )
      },
    },
    {
      field: 'level',
      headerName: 'Nivel',
      flex: '1',
      exportField: true,
      render: ({ level }) => {
        const rowLevel = EXCEPTION_LEVELS.find((item) => item.value === level);
        return rowLevel?.name;
      },
    },
    {
      field: 'counter',
      headerName: 'Conteo',
      flex: '0.5',
      exportField: true,
    },
    {
      field: 'isResolved',
      headerName: 'Estado',
      flex: '1',
      exportField: true,
      overflowNone: true,
      isState: true,
      enableToggle: true,
      render: ({ isResolved, id }) => (
        <Toggle
          isChecked={isResolved}
          onToggleChange={onCheck}
          sourceElement={id}
          toggleSize='sm'
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Acción',
      disabledSort: true,
      flex: '0.3',
      align: 'flex-start',
      render: ({ id }) => {
        return (
          <>
            <IconButton
              info="Ver factura"
              positionEdge="start"
              color="grey"
              onClick={() => onViewDetails(id)}
            >
              <AiOutlineEye />
            </IconButton>
          </>
        );
      },
    },
  ]
  return columns;
};

/**
 * Función para crear filtros del paginador
 * @param param0 
 * @returns 
 */
export const createFilterModel = (
  { message, customer, process, level, extradata, dateRange, isResolved }: ListExceptionFilterModel) => {

  const filterMessage = createFilterStructure(
    'message',
    'contains',
    message,
  );


  const filterExtraData = createFilterStructure(
    'extradata',
    'jsonContains',
    extradata,
  );

  const filterDateRange = createFilterStructure(
    'created_at',
    'between_date',
    joinObjectWithFormat(dateRange, '.', format, [
      DATE_FORMAT_BACK_WITH_OUT_TIME,
    ]),
  );

  let modelFilter = [
    filterMessage,
    filterDateRange,
    filterExtraData,
  ].filter((item) => item);

  if (level) {
    const filterLevel = createFilterStructure(
      'level',
      'in',
      level?.split(","),
    );
    modelFilter = [
      filterLevel,
      ...modelFilter
    ]
  }

  if (isResolved) {
    const filterStatus = createFilterStructure(
      'isResolved',
      'equals',
      isResolved === 'true' ? 'true' : 'false',
    );
    modelFilter = [
      filterStatus,
      ...modelFilter
    ]
  }

  if (process) {
    const filterProcess = createFilterStructure(
      'process',
      'in',
      process?.split(","),
    );
    modelFilter = [
      filterProcess,
      ...modelFilter
    ]
  }

  if (customer) {
    const filterCustomer = createFilterStructure(
      'customer',
      'in',
      customer?.split(","),
    );
    modelFilter = [
      filterCustomer,
      ...modelFilter
    ]
  }
  return modelFilter;
}


export const fetchProcess = (
  pagination: tableModelType<Exception>,
  config?: AxiosRequestConfig<Exception>,
) => {

  return axios.get<PaginationType<Exception>>(
    `${GET_EXCEPTIONS}${createPayload(pagination)}`,
    config,
  );
}

export const updateExceptions = async (
  { exceptions, setModifiedExceptions, setOpen, setErrorMessage, setActionSnackBar, setIsUpdating }: updateExceptionsProps
) => {
  return axios.put(
    `${UPDATE_EXCEPTIONS}`,
    {
      batchUpdateExceptionDto: exceptions,
    },
  ).then(() => {
    setOpen('success');
    setActionSnackBar('confirm');
  }).catch((error) => {
    setOpen('error');
    console.log(error);
    if (error.response && error.response.data && error.response.data.message) {
      setErrorMessage(error.response.data.message);
      setOpen('warning');
    } else {
      setErrorMessage(undefined);
      setOpen('error');
    }
  }).finally(() => {
    setModifiedExceptions([]);
    setIsUpdating(false);
  });
}