import { AxiosRequestConfig } from 'axios';
import moment from 'moment';

export const Providers = [
  'Contabo',
  'AWS',
  'Azure',
  'Google',
  'Digital Ocean',
  'C&W',
];

export const OperatingSystems = ['Windows Server', 'Linux'];

export const StatusColor = {
  Disponible: 'green',
  'No Disponible': 'red',
};

export const formatDate = (date: string) => {
  return date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : '';
};

export const serverMonitoringConfig: AxiosRequestConfig = {
  auth: {
    username: process.env.REACT_APP_MONITORING_API_USER as string,
    password: process.env.REACT_APP_MONITORING_API_PASSWORD as string,
  },
  baseURL: process.env.REACT_APP_MONITORING_API as string,
};
