import 'config/axios';
import 'config/yup';
import { PrivateLayout, PublicLayout } from 'layouts';
import {
  ChangePasswordPage, CreateAMPage, CreateCompanyPage, CreateEditServicePage, CreateGroupingPage, CreateProjectPage, CreateRolePage, CreateTaskPage, CreateUserPage, EditAMPage, EditCompanyPage, EditGroupingPage, EditProjectPage, EditRolePage,
  EditUserPage,
  FixTransactionPage,
  HistoryPage,
  HistoryPendingPage,
  HomePage, ListAMBotPage, ListAMPage, ListBotPage, ListCompanyPage, ListExceptionsPage, ListGroupingPage, ListProjectPage, ListRolePage,
  ListServerPage,
  ListServicePage,
  ListTaskPage, ListUserPage, LoginPage,
  TransactionPageReport,
  TransactionPageVerification
} from 'pages';
import DashboardPage from 'pages/PublicServicesPages/DashboardPage';
import CreateEditServerPage from 'pages/ServerPage/CreateEditServerPage/CreateEditServerPage';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Navigate, Route, Routes } from 'react-router-dom';


function App() {

  return (
    <Routes>
      <Route path="dashboard" element={<Navigate to="/pages/home" />} />
      <Route path="/" element={<PublicLayout />}>
        <Route index element={<Navigate to="/login" />} />
        <Route path="login" element={<LoginPage />} />
      </Route>

      <Route path="*" element={<Navigate to="/dashboard" />} />
      <Route path="pages" element={<PrivateLayout />}>
        <Route index />
        <Route path="home" element={<HomePage />} />

        {/* Monitoring Services */}
        <Route path="monitoring">
          <Route path="services">
            <Route index element={<ListServicePage />} />
            <Route path="create" element={<CreateEditServicePage />} />
            <Route path=":id/edit" element={<CreateEditServicePage />} />
          </Route>
          <Route path="servers">
            <Route index element={<ListServerPage />} />
            <Route path=":id/services"  >
              <Route index element={<ListServicePage />} />
            </Route>
            <Route path="create" element={<CreateEditServerPage />} />
            <Route path=":id/edit" element={<CreateEditServerPage />} />
          </Route>
        </Route>

        <Route path="account">
          {/* <Route index element={<ListUserPage />} /> */}
          <Route path="change_password" element={<ChangePasswordPage />} />
        </Route>
        <Route path="bot" element={<ListBotPage />}>
        </Route>
        <Route path="task">
          <Route index element={<ListTaskPage />} />
          <Route path="create" element={<CreateTaskPage />} />
        </Route>
        {/* para agregar el update */}
        <Route path="management">
          <Route path="user">
            <Route index element={<ListUserPage />} />
            <Route path="create" element={<CreateUserPage />} />
            <Route path=":id/edit" element={<EditUserPage />} />
          </Route>
          <Route path="role">
            <Route index element={<ListRolePage />} />
            <Route path="create" element={<CreateRolePage />} />
            <Route path=":id/edit" element={<EditRolePage />} />
          </Route>
          <Route path="automationmanager">
            <Route index element={<ListAMPage />} />
            <Route path=":id/bot"  >
              <Route index element={<ListAMBotPage />} />
            </Route>
            <Route path="create" element={<CreateAMPage />} />
            <Route path=":id/edit" element={<EditAMPage />} />
          </Route>
          <Route path="exceptions" element={<ListExceptionsPage />} />
          <Route path="bot">
            <Route index element={<ListAMBotPage />} />
            {/* <Route path="create" element={<CreateBotPage />} />
            <Route path=":id/edit" element={<EditBotPage />} />*/}
          </Route>
        </Route>

        <Route path="process">
          <Route path=":id" element={<TransactionPageReport />} />
          <Route path=":id/fix" element={<TransactionPageVerification />} />
          <Route path=":id/fix/:idDocument" element={<FixTransactionPage />} />
        </Route>

        <Route path="servicios-publicos">
          <Route path="management/company" element={<ListCompanyPage />} />
          <Route path="management/company/create" element={<CreateCompanyPage />} />
          <Route path="management/company/:id/edit" element={<EditCompanyPage />} />
          <Route path="management/project" element={<ListProjectPage />} />
          <Route path="management/project/create" element={<CreateProjectPage />} />
          <Route path="management/project/:id/edit" element={<EditProjectPage />} />
          <Route path="inmueble" element={<ListGroupingPage />} />
          <Route path="inmueble/create" element={<CreateGroupingPage />} />
          <Route path="inmueble/:id/edit" element={<EditGroupingPage />} />
          <Route path="reportes/historico" element={<HistoryPage />} />
          <Route path="reportes/pendientes" element={<HistoryPendingPage />} />
          <Route path="dashboard" element={<DashboardPage />} />
        </Route>

      </Route>
    </Routes>
  );
}

export default App;
